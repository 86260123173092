<template>
  <div class="errorP">
    <div class="errorP-inner">
      <h1><img src="@/assets/img/sub/notice.png" alt=""></h1>
      <p>삭제되었거나 존재하지 않는 게시물입니다.</p>
      <a @click="goBack" class="btn color-1 btn-large w-200 btn-round100">이전 페이지 이동</a>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'BasicLayout',
  data() {
    return {
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>